<template>
  <div class="calculator">
    <h2>ผลลัพธ์การคำนวณ VAT</h2>
    <p>อัตราภาษี VAT: {{ vatRate.toFixed(2) }}%</p>
    <p>อัตราภาษีหัก ณ ที่จ่าย: {{ withholdingTaxRate.toFixed(2) }}%</p>
    <p>ภาษีมูลค่าเพิ่ม (VAT): {{ vatAmount.toFixed(2) }}</p>
    <p>ราคาหลังจากหัก ณ ที่จ่าย: {{ priceAfterWithholdingTax.toFixed(2) }}</p>
  </div>
</template>

<script>
export default {
  props: {
    vatAmount: {
      type: Number,
      required: true,
    },
    priceAfterVAT: {
      type: Number,
      required: true,
    },
    priceAfterWithholdingTax: {
      type: Number,
      required: true,
    },
  },
  computed: {
    vatRate() {
      return (this.vatAmount / this.priceAfterVAT) * 100 || 0;
    },
    withholdingTaxRate() {
      return (
        ((this.priceAfterVAT - this.priceAfterWithholdingTax) /
          this.priceAfterVAT) *
          100 || 0
      );
    },
  },
};
</script>

