<template>
  <div class="home">
    <header class="header">
      <a href="https://inflowaccount.co.th/">
        <img :src="require('@/assets/new_logo-1.png')" alt="Logo" class="logo" />
      </a>
      <h1></h1>
    </header>
    <div class="content" style="margin-top: 100px;">
      <h1>คำนวณภาษีหัก ณ ที่จ่าย และคำนวณภาษีมูลค่าเพิ่มออนไลน์</h1>
      <br />
      <div class="space">
        <div class="row justify-content-start align-items-start">
          <div class="col-auto">
            <label for="priceType" style="font-size: 17px; color: #777f96"
              >ประเภทราคา:</label
            >
          </div>
          <div class="col-auto" style="margin-left: -25px; margin-top: 2px">
            <select id="priceType" class="pricetype" v-model="priceType">
              <option value="exclusive">ราคาไม่รวม VAT</option>
              <option value="inclusive">ราคารวม VAT</option>
            </select>
          </div>
        </div>
        <div class="input-group">
          <div class="input-tax">
            <div class="input-group">
              <span class="vat">฿</span>
              <b-form-input
                id="priceInput"
                v-model="price"
                @focus="clearPrice"
                @input="formatPrice"
                placeholder="กรุณากรอกราคา"
                style="color: #777f96"
              ></b-form-input>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="d-flex align-items-center">
            <div class="col">
              <div class="d-flex align-items-center">
                <div class="label" style="margin-right: 100px; color: #777f96">
                  ภาษีมูลค่าเพิ่ม (VAT)
                </div>
                <b-input-group
                  class="custom-width"
                  size="lg"
                  append="%"
                  style="color: #777f96"
                >
                  <b-form-input
                    class="price"
                    type="number"
                    v-model="vatOption"
                    style="color: #777f96; border: 1px solid #dee2e6"
                  />
                </b-input-group>
              </div>
            </div>
          </div>

          <div class="d-flex align-items-center">
            <div class="col">
              <div class="d-flex align-items-center">
                <div class="label" style="margin-right: 130px; color: #777f96">
                  หักภาษี ณ ที่จ่าย
                </div>
                <b-input-group
                  class="custom-width"
                  size="lg"
                  append="%"
                  style="color: #777f96"
                >
                  <b-form-input
                    class="price"
                    type="number"
                    v-model="withholdingTaxOption"
                    style="color: #777f96; border: 1px solid #dee2e6"
                  />
                </b-input-group>
              </div>
            </div>
          </div>
        </div>

        <b-button
          variant="success"
          @click="calculateVAT"
          :disabled="disableButton"
          >คำนวณ</b-button
        >
      </div>
    </div>
    <div style="text-align: center;">
      <img src="/images/1.jpg" alt="รูปภาพของคุณ" style="display: inline-block; width: 700px; height: 400px;">
    </div>
    <div class="wrap-price space_1" v-if="showResult">
      <h3>ผลการคำนวณ</h3>
      <div class="row">
        <div class="col">
          <div class="result-column">
            <p>ราคาก่อน VAT</p>
          </div>
        </div>
        <div class="col">
          <div class="result-column">
            <p>{{ priceBeforeVATWithComma }}</p>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <div class="result-column">
            <p>ภาษีมูลค่าเพิ่ม {{ vatOption }}%</p>
          </div>
        </div>
        <div class="col">
          <div class="result-column">
            <p>{{ vatAmountWithComma }}</p>
          </div>
        </div>
      </div>

      <div class="row result-row">
        <div class="col">
          <div class="result-column">
            <p>ราคารวมภาษีมูลค่าเพิ่ม</p>
          </div>
        </div>
        <div class="col">
          <div class="result-column">
            <p>{{ priceAfterVATWithComma }}</p>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <div class="result-column">
            <p>หัก ภาษี ณ ที่จ่าย {{ withholdingTaxOption }}%:</p>
          </div>
        </div>
        <div class="col">
          <div class="result-column">
            <p>{{ withholdingTaxAmountWithComma }}</p>
          </div>
        </div>
      </div>

      <div class="row result-row">
        <div class="col">
          <div class="result-column">
            <p>ราคาสุทธิ</p>
          </div>
        </div>
        <div class="col">
          <div class="result-column">
            <p>{{ finalPriceWithComma }}</p>
          </div>
        </div>
      </div>
      <div class="random-link">
        <p>
          <!-- หากผลการคำนวณภาษีมูลค่าเพิ่ม (VAT) ของคุณเกิน 1.8 ล้านบาท และมี VAT
          ที่ต้องเสีย
          <a :href="randomLink" target="_blank"
            >สามารถดูตัวช่วยในการขอจดทะเบียน VAT ได้ที่นี่</a
          > -->
          หากต้องการคำแนะนำวางแผนเรื่องภาษี บัญชี สามารถทักแชทได้ที่
          <a
            class="color-tax"
            href="https://lin.ee/911BqsX"
            target="_blank"
            rel="noopener noreferrer"
            >@ac567</a
          >
          หรือ
          <a class="color-tax" href="tel:0910059903">โทร.091-005-9903</a>
          เพราะทุกปัญหาเรื่องภาษี บัญชีใกล้ตัวคุณมากกว่าที่คุณรู้
          <a
            class="color-tax"
            href="https://lin.ee/911BqsX"
            target="_blank"
            rel="noopener noreferrer"
            >ติดต่อหาเราโดยตรง</a
          >
        </p>
      </div>
      <b-button variant="danger" @click="reset">เริ่มใหม่</b-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imageURL: process.env.BASE_URL + 'images/1.jpg',
      priceType: "exclusive",
      price: "",
      vatOption: 7,
      withholdingTaxOption: 3,
      priceBeforeVAT: 0,
      vatAmount: 0,
      priceAfterVAT: 0,
      withholdingTaxAmount: 0,
      finalPrice: 0,
      showResult: false,
      randomLink: "",
      link1: "https://inflowaccount.co.th/tax-invoice/",
      link2: "https://inflowaccount.co.th/when-should-you-register-vat/",
      disableButton: true,
    };
  },
  watch: {
    price: function (newPrice) {
      if (newPrice !== "") {
        this.disableButton = false;
      } else {
        this.disableButton = true;
      }
    },
  },
  computed: {
    priceBeforeVATWithComma() {
      return this.formatNumber(this.priceBeforeVAT);
    },
    vatAmountWithComma() {
      return this.formatNumber(this.vatAmount);
    },
    priceAfterVATWithComma() {
      return this.formatNumber(this.priceAfterVAT);
    },
    withholdingTaxAmountWithComma() {
      return this.formatNumber(this.withholdingTaxAmount);
    },
    finalPriceWithComma() {
      return this.formatNumber(this.finalPrice);
    },
    // randomLink() {
    //   return Math.random() < 0.5 ? this.link1 : this.link2;
    // },
  },
  mounted() {
    this.setRandomLink();
  },
  methods: {
    setRandomLink() {
      this.randomLink = Math.random() < 0.5 ? this.link1 : this.link2;
    },
    clearPrice() {
      this.price = "";
    },
    // formatPrice() {
    //   this.price = this.price.replace(/\D/g, "");
    //   this.price = Number(this.price).toLocaleString();
    // },
    formatPrice() {
        this.price = this.price.replace(/[^\d.]/g, "");
        const parts = this.price.split(".");
        parts[0] = parts[0].replace(/\D/g, ""); 
        this.price = parts.join(".");
        if (this.price.endsWith(".")) {
          this.price = this.price.slice(0, -1);
        }
      },


    calculateVAT() {
      this.showResult = true;

      const vatRate = this.vatOption / 100;
      const withholdingTaxRate = this.withholdingTaxOption / 100;

      const priceNumeric = parseFloat(this.price.replace(/,/g, "")) || 0;

      if (this.priceType === "exclusive") {
        this.priceBeforeVAT = priceNumeric;
        this.priceAfterVAT = this.priceBeforeVAT * (1 + vatRate);
        this.vatAmount = this.priceAfterVAT - this.priceBeforeVAT;
      } else if (this.priceType === "inclusive") {
        this.priceAfterVAT = priceNumeric;
        this.priceBeforeVAT = this.priceAfterVAT / (1 + vatRate);
        this.vatAmount = this.priceAfterVAT - this.priceBeforeVAT;
      }

      this.withholdingTaxAmount = this.priceBeforeVAT * withholdingTaxRate;
      this.finalPrice = this.priceAfterVAT - this.withholdingTaxAmount;
      this.setRandomLink();
    },
    reset() {
      this.priceType = "exclusive";
      this.price = 0;
      this.vatOption = 7;
      this.withholdingTaxOption = 3;
      this.priceBeforeVAT = 0;
      this.vatAmount = 0;
      this.priceAfterVAT = 0;
      this.withholdingTaxAmount = 0;
      this.finalPrice = 0;
      this.showResult = false;
      this.setRandomLink();
    },
    formatNumber(value) {
      return value.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
  },
};
</script>

<style scoped>
@import "@/assets/css/TheHome.css";
.custom-width {
  width: 150px;
}

.header {
  background-color: rgba(0, 0, 0, 0.79);
  color: white;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}


  .logo {
    width: auto; 
    height: auto;
  }
  .main-content {
    margin-top: 70px; 
  }
</style>
