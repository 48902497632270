<template>
  <div>
    <TheCalculator
      :vatAmount="vatAmount"
      :priceAfterVAT="priceAfterVAT"
      :priceAfterWithholdingTax="priceAfterWithholdingTax"
    />
  </div>
</template>

<script>
import TheCalculator from "@/components/TheCalculator.vue";

export default {
  name: "CalculatorView",
  data() {
    return {
      vatAmount: 0,
      priceAfterVAT: 0,
      priceAfterWithholdingTax: 0,
    };
  },
  components: {
    TheCalculator,
  },
};
</script>
