<template>
  <TheHome />
</template>
<script>
// @ is an alias to /src
import TheHome from "@/components/TheHome.vue";

export default {
  name: "HomeView",
  components: {
    TheHome,
  },
};
</script>
